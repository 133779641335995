@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .h-iphone-viewport-height.device-is-iphone {
    height: var(--iphone-viewport-height, 100%);
    bottom: auto;
  }

  @supports (height: 100dvh) {
    .h-iphone-viewport-height,
    .h-iphone-viewport-height.device-is-iphone {
      height: 100dvh;
      bottom: auto;
    }
  }
}
